/** Icons generated by create-react-icons. Don't edit this file directly. **/
import React from "react";
import PropTypes from "prop-types";

export function IconChevronDown(props) {
  const { size, color, ...other } = props;
  return (
    <svg viewBox="0 0 13 8" fill="none" height={size} width={size} {...other}>
      <path
        d="M1.5 1L6.5 6L11.5 1"
        stroke={color}
        strokeOpacity="0.8"
        strokeWidth="1.5"
      />
    </svg>
  );
}

IconChevronDown.defaultProps = {
  color: "currentColor",
  size: 24
};

IconChevronDown.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
