/** Icons generated by create-react-icons. Don't edit this file directly. **/
import React from "react";
import PropTypes from "prop-types";

export function IconClose(props) {
  const { size, color, ...other } = props;
  return (
    <svg viewBox="0 0 17 18" fill="none" height={size} width={size} {...other}>
      <path
        d="M0.5 1L8.5 9M16.5 17L8.5 9M8.5 9L16.5 1M8.5 9L0.5 17"
        stroke={color}
      />
    </svg>
  );
}

IconClose.defaultProps = {
  color: "currentColor",
  size: 24
};

IconClose.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
